import { Injectable } from "@angular/core";
import SockJS from "sockjs-client";
import { environment } from "src/environments/environment";
import * as Stomp from 'stompjs';

@Injectable({
  providedIn: 'root',
})
export class SocketClientService {

  private stompClient: Stomp.Client;
  private connected: boolean = false;

  connect(companyId: number, subscriptions: any[]): Promise<string> {
    return new Promise<string>((resolve, reject) => {
      if (this.connected) {
        for (const subscription of subscriptions) {
          this.stompClient.subscribe(subscription.topic, subscription.handler);
        }
        resolve('Already connected');
        return;
      }
      const socket = new SockJS(`${environment.wsBasePath}/message-broker`);
      this.stompClient = Stomp.over(socket);
      const connectCallback = () => {
        this.connected = true;
        for (const subscription of subscriptions) {
          this.stompClient.subscribe(subscription.topic, subscription.handler);
        }
        resolve('ok');
      };
      const errorCallback = () => {
        console.log('Error connecting to WebSocket. Retrying in 2 seconds...');
        setTimeout(() => {
          this.connect(companyId, subscriptions).then(() => {
            console.log('Reconnected to WebSocket successfully');
          }).catch((error) => {
            console.error('Error reconnecting to WebSocket', error);
          });
        }, 1000);
        reject('bad');
      };
      this.stompClient.connect({
        channel: `company_${companyId}`
      }, connectCallback, errorCallback);
    });
  }
  
  disconnect() {
    if (this.connected) {
      this.stompClient.disconnect(() => {
        console.log('Disconnected from websocket server');
        this.connected = false;
      });
    }
  }

}