
const basePath = 'https://api-prod.agencialeveloop.com';

export const environment = {
  production: true,
  basePath: basePath,
  authServerUrl: `${basePath}/oauth/token`,
  wsBasePath: basePath,
  facebookAppId: '1232043421069881'
};
