import { AuthConfig } from 'angular-oauth2-oidc';
import { environment } from 'src/environments/environment';

export const authConfig: AuthConfig = {
    useHttpBasicAuth: true,
    tokenEndpoint: environment.authServerUrl,
    scope: "foo read write",
    requireHttps: false,
    clientId: 'feedback-intelligence',
    dummyClientSecret: "intelligence_dummy_secret"
};
