import { Injectable, Output } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../environments/environment';
import { Conversation } from 'src/app/model/conversation/conversation';
import { Contact } from 'src/app/model/conversation/contact';
import { UpdateContactName } from 'src/app/model/conversation/update-contact-name';
import { SimpleConversation } from 'src/app/model/conversation/simple-conversation';
import { GetConversationsRequest } from 'src/app/model/conversation/get-conversations-request';
import { Page } from 'src/app/model/page/page';

@Injectable({
  providedIn: 'root',
})
export class ConversationService {

  httpHeaders: HttpHeaders;

  constructor(protected httpClient: HttpClient) {
    this.httpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
    });
  }

  getConversation(conversationId: number): Observable<Conversation> {
    const endpoint = `${environment.basePath}/conversations/get-conversation/${conversationId}`;
    return this.httpClient.get<Conversation>(endpoint);
  }

  getSimpleConversation(conversationId: number): Observable<SimpleConversation> {
    const endpoint = `${environment.basePath}/conversations/get-simple-conversation/${conversationId}`;
    return this.httpClient.get<SimpleConversation>(endpoint);
  }

  countUnreadConversations(): Observable<number> {
    const endpoint = `${environment.basePath}/conversations/count-unread-conversations`;
    return this.httpClient.get<number>(endpoint);
  }


  countUnreadConversationsByBoardStatus(boardStatusId: number): Observable<number> {
    const endpoint = `${environment.basePath}/conversations/count-unread-conversations-by-board-status/${boardStatusId}`;
    return this.httpClient.get<number>(endpoint);
  }

  assignUserToConversation(conversationId: number, userId: number): Observable<any> {
    const endpoint = `${environment.basePath}/conversations/assign-user/${conversationId}/${userId}`;
    return this.httpClient.post<any>(endpoint, null);
  }

  getConversations(archived: boolean): Observable<SimpleConversation[]> {
    const endpoint = `${environment.basePath}/conversations/get-conversations?archived=${archived}`;
    return this.httpClient.get<SimpleConversation[]>(endpoint);
  }

  getConversationsNew(request: GetConversationsRequest, page: number, size: number): Observable<Page<SimpleConversation>> {

    const endpoint = `${environment.basePath}/conversations/get-conversations`;

    let params = new HttpParams()
      .append('page', page.toString())
      .append('size', size.toString());

    return this.httpClient.post<Page<SimpleConversation>>(endpoint, request, {
      headers: this.httpHeaders,
      params: params
    });
  }

  getUnreadConversations(): Observable<SimpleConversation[]> {
    const endpoint = `${environment.basePath}/conversations/get-unread-conversations`;
    return this.httpClient.get<SimpleConversation[]>(endpoint);
  }

  searchConversations(request: GetConversationsRequest): Observable<SimpleConversation[]> {
    const endpoint = `${environment.basePath}/conversations/search-conversations`;
    return this.httpClient.post<SimpleConversation[]>(endpoint, request);
  }

  getPageConversations(archived: boolean, page: number, size: number): Observable<any> {
    let params = new HttpParams()
      .append('page', page.toString())
      .append('size', size.toString());
    const endpoint = `${environment.basePath}/conversations/get-page-conversations?archived=${archived}`;

    return this.httpClient.get<any>(endpoint, {
      headers: this.httpHeaders,
      params: params
    });
  }

  getFilteredConversations(labelId: number): Observable<SimpleConversation[]> {
    const endpoint = `${environment.basePath}/conversations/get-filtered-conversations/${labelId}`;
    return this.httpClient.get<SimpleConversation[]>(endpoint);
  }

  getFilteredConversationsByBoardStatus(labelId: number, boardStatusId: number): Observable<SimpleConversation[]> {
    const endpoint = `${environment.basePath}/conversations/get-filtered-conversations-by-board/${labelId}/${boardStatusId}`;
    return this.httpClient.get<SimpleConversation[]>(endpoint);
  }

  getFilteredConversationsByUser(userId: number, boardStatusId: number): Observable<SimpleConversation[]> {
    const endpoint = `${environment.basePath}/conversations/get-filtered-conversations-by-user/${userId}/${boardStatusId}`;
    return this.httpClient.get<SimpleConversation[]>(endpoint);
  }

  getUserConversations(userId: number): Observable<SimpleConversation[]> {
    const endpoint = `${environment.basePath}/conversations/get-user-conversations/${userId}`;
    return this.httpClient.get<SimpleConversation[]>(endpoint);
  }

  updateClientName(contact: UpdateContactName, conversationId: number, contactId: number): Observable<Contact> {
    const endpoint = `${environment.basePath}/conversations/update/${conversationId}/contact/${contactId}`;
    return this.httpClient.put<Contact>(endpoint, contact);
  }

  setExpiredConversation(conversationId: number, expired: boolean): Observable<any> {
    const endpoint = `${environment.basePath}/conversations/expire/${conversationId}?expired=${expired}`;
    return this.httpClient.put<any>(endpoint, null);
  }

  setArchivedConversation(conversationId: number, archived: boolean, boardId: number): Observable<any> {
    const endpoint = `${environment.basePath}/conversations/archived/${conversationId}?archived=${archived}&boardId=${boardId}`;
    return this.httpClient.put<any>(endpoint, null);
  }

  setReadConversation(conversationId: number, read: boolean, boardId: number): Observable<any> {
    const endpoint = `${environment.basePath}/conversations/read/${conversationId}?read=${read}&boardId=${boardId}`;
    return this.httpClient.put<any>(endpoint, null);
  }
}
