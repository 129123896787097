import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';

// shared module
import { SharedModule } from '../shared/shared.module';

// components
import { LayoutComponent } from './layout/layout.component';
import { NgbAlertModule, NgbDropdownModule, NgbToastModule } from '@ng-bootstrap/ng-bootstrap';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SimplebarAngularModule } from 'simplebar-angular';



@NgModule({
  declarations: [
    LayoutComponent,
  ],
  imports: [
    CommonModule,
    FormsModule,
    NgbDropdownModule,
    SharedModule,
    SimplebarAngularModule,
    ReactiveFormsModule,
    NgbToastModule,
    NgbAlertModule,
    RouterModule
  ],
  exports: [LayoutComponent]
})
export class VerticalModule { }
