import es from '@angular/common/locales/es';
import { registerLocaleData } from '@angular/common';

import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { LOCALE_ID, NgModule } from '@angular/core';
import { BrowserModule, Title } from '@angular/platform-browser';
import { QuillModule } from 'ngx-quill';

import { LayoutModule } from './layouts/layout.module';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { TokenInterceptor } from './core/helpers/token-interceptor';
import { DataStorageService } from 'src/services/data/data-storage';
import { OAuthModule } from 'angular-oauth2-oidc';
import { AuthService } from './core/service/auth.service';
import { ToastrModule } from 'ngx-toastr';
import { FacebookModule } from 'ngx-facebook';
import { AuthInterceptor } from './core/helpers/unauthorized-interceptor';
import { AuthGuard } from './core/helpers/auth.guard';
import { SimplebarAngularModule } from 'simplebar-angular';

registerLocaleData(es);

@NgModule({
  declarations: [
    AppComponent,
  ],
  imports: [
    BrowserModule,
    HttpClientModule,
    QuillModule.forRoot(),
    ToastrModule.forRoot(),
    BrowserAnimationsModule,
    SimplebarAngularModule,
    LayoutModule,
    OAuthModule.forRoot(),
    AppRoutingModule,
    FacebookModule.forRoot()
  ],
  providers: [
    Title,
    AuthService,
    AuthGuard,
    { provide: LOCALE_ID, useValue: 'es-MX' },
    { provide: DataStorageService, useClass: DataStorageService },
    { 
      provide: HTTP_INTERCEPTORS,
      useClass: TokenInterceptor, 
      multi: true 
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true,
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
