import { Injectable, Output } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../environments/environment';
import { Note } from 'src/app/model/note/note';
import { NoteRequest } from 'src/app/model/note/note-request';

@Injectable({
  providedIn: 'root',
})
export class NoteService {

  httpHeaders: HttpHeaders;

  constructor(protected httpClient: HttpClient) {
    this.httpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
    });
  }

  exportNotes(conversationId: number): Observable<Blob> {
    const endpoint = `${environment.basePath}/notes/${conversationId}/export`;
    return this.httpClient.get(endpoint, {
      responseType: 'blob'
    })
  }

  exportAllNotes(): Observable<Blob> {
    const endpoint = `${environment.basePath}/notes/export`;
    return this.httpClient.get(endpoint, {
      responseType: 'blob'
    })
  }

  getNotes(conversationId: number): Observable<Note[]> {
    const endpoint = `${environment.basePath}/notes/${conversationId}/get-notes`;
    return this.httpClient.get<Note[]>(endpoint);
  }

  sendNote(note: NoteRequest): Observable<Note> {
    const endpoint = `${environment.basePath}/notes`;
    return this.httpClient.post<Note>(endpoint, note);
  }

  updateNote(description: string, noteId: any): Observable<Note> {
    let params = new HttpParams().set('description', description);
    const endpoint = `${environment.basePath}/notes/${noteId}`;
    return this.httpClient.put<Note>(endpoint, null, {
      params: params
    });
  }

  deleteNote(noteId: number): Observable<any> {
    const endpoint = `${environment.basePath}/notes/${noteId}`;
    return this.httpClient.delete(endpoint);
  }

}
