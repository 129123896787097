import { Injectable } from '@angular/core';

import { HttpClient, HttpHeaders, HttpParams, HttpRequest } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { GetPresignedUrlRequest } from 'src/app/model/media/get-presigned-url-request';

@Injectable({
    providedIn: 'root'
})
export class MediaService {

    httpHeaders: HttpHeaders;

    constructor(protected httpClient: HttpClient) {
        this.httpHeaders = new HttpHeaders({
            'Content-Type': 'application/json',
        });
    }

    getPresignedUrl(fileType: string) {

        const params = new HttpParams()
            .set('contentType', fileType);

        const endpoint = `${environment.basePath}/media/get-presigned-url`;

        return this.httpClient.get<any>(endpoint, { params: params, headers: this.httpHeaders });
    }

    getSignedUrl(presignedUrl: GetPresignedUrlRequest): Observable<any> {
        const endpoint = `${environment.basePath}/media/get-signed-url`;
        return this.httpClient.post(endpoint, presignedUrl);
    }

    uploadMedia(signedUrl: string, file: any) {

        const endpoint = signedUrl;
        
        const headers = new HttpHeaders({
            'Content-Type': file.type,
            'Ignore-Authorization': 'true'
        });

        return this.httpClient.put<any>(endpoint, file, {
            headers: headers
        });
    }

    sendMediaMessageV2(request: any) {

        const endpoint = `${environment.basePath}/media/send-media-message`;

        return this.httpClient.post<any>(endpoint, request);
    }

    sendMediaMessage(attachedFile: any, to: string) {

        const formData = new FormData();
        formData.append('file', attachedFile.file);
        formData.append('to', to);
        formData.append('identifier', attachedFile.identifier);

        const endpoint = `${environment.basePath}/media/send-media-message`;

        return this.httpClient.post<any>(endpoint, formData, {
            reportProgress: true,
            observe: 'events'
        });
    }

    getObject(key: string): Observable<Blob> {

        const endpoint = `${environment.basePath}/media/get-object`;

        let params = new HttpParams().set("key", key)
        let blob = this.httpClient.get<Blob>(endpoint, {
            headers: this.httpHeaders,
            params: params,
            responseType: 'blob' as 'json'
        });

        return blob;
    }

    getObjectV2(url: string): Observable<Blob> {

        const headers = new HttpHeaders({
            'Ignore-Authorization': 'true'
        });

        let blob = this.httpClient.get<Blob>(url, {
            headers: headers,
            responseType: 'blob' as 'json'
        });

        return blob;
    }
}