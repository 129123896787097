import { HttpClient, HttpHeaders, HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { Conversation } from "src/app/model/conversation/conversation";
import { AssingLabelRequest } from "src/app/model/label/assign-label-request";
import { Label } from "src/app/model/label/label";
import { LabelRequest } from "src/app/model/label/label-request";
import { environment } from "src/environments/environment";

@Injectable({
    providedIn: 'root',
})
export class LabelService {

    httpHeaders: HttpHeaders;

    constructor(protected httpClient: HttpClient) {
        this.httpHeaders = new HttpHeaders({
            'Content-Type': 'application/json',
        });
    }

    getLabels(): Observable<Label[]> {
        const endpoint = `${environment.basePath}/label/get-labels`;
        return this.httpClient.get<Label[]>(endpoint);
    }

    saveLabel(label: LabelRequest): Observable<Label> {
        const endpoint = `${environment.basePath}/label`;
        return this.httpClient.post<Label>(endpoint, label);
    }

    addLabelToPipelineConversations(labelId: number, boardStatusId: number, boardId: number): Observable<Label> {
        let params = new HttpParams()
            .set('labelId', labelId.toString())
            .set('boardStatusId', boardStatusId.toString())
            .set('boardId', boardId.toString());
        const endpoint = `${environment.basePath}/label/add-label-pipeline-conversations`;
        return this.httpClient.post<any>(endpoint, null, {
            headers: this.httpHeaders,
            params: params
        });
    }
    

    updateLabel(label: LabelRequest, labelId: number): Observable<Label> {
        const endpoint = `${environment.basePath}/label/${labelId}`;
        return this.httpClient.put<Label>(endpoint, label);
    }

    setLabelToConversation(body: AssingLabelRequest) {
        const endpoint = `${environment.basePath}/label/set-label`;
        return this.httpClient.put(endpoint, body);
    }

    removeLabel(labelId: number, conversationId: number): Observable<any> {
        const endpoint = `${environment.basePath}/label/${conversationId}/${labelId}`;
        return this.httpClient.delete(endpoint);
    }

    deleteLabel(labelId: number, conversationId: number): Observable<any> {
        const endpoint = `${environment.basePath}/label/delete/${conversationId}/${labelId}`;
        return this.httpClient.delete(endpoint);
    }

}