import { HttpClient, HttpHeaders, HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable, map } from "rxjs";
import { WhatsappMessageTemplate } from "src/app/model/whatsapp-message-template/whatsapp-message-template";
import { WhatsappMessageTemplateRequest } from "src/app/model/whatsapp-message-template/whatsapp-message-template-request";

import { environment } from "src/environments/environment";


@Injectable({
    providedIn: 'root',
})
export class WhatsappMessageTemplateService {

    httpHeaders: HttpHeaders;

    constructor(protected httpClient: HttpClient) {
        this.httpHeaders = new HttpHeaders({
            'Content-Type': 'application/json',
        });
    }

    saveTemplate(template: WhatsappMessageTemplateRequest, file: File): Observable<any> {
        const endpoint = `${environment.basePath}/message-templates/create`;

        const formData = new FormData();
        formData.append('template', JSON.stringify(template));
        if (file) {
            formData.append('file', file);
        }

        const headers = new HttpHeaders();
        headers.append('Accept', 'application/json');

        return this.httpClient.post<any>(endpoint, formData, { headers });
    }

    generatePresignedUrlTemplate(key: string): Observable<any> {
        const params = new HttpParams().set('key', key);
        const endpoint = `${environment.basePath}/message-templates/get-presigned-url`;
        return this.httpClient.get<any>(endpoint, {
            headers: new HttpHeaders().set('Accept', 'application/json'),
            params: params,
            responseType: 'json'
        })
    }

    getMessageTemplates(): Observable<WhatsappMessageTemplate[]> {
        const endpoint = `${environment.basePath}/message-templates`;
        return this.httpClient.get<WhatsappMessageTemplate[]>(endpoint);
    }

    updateTemplate(messageTemplateId: string,
        template: WhatsappMessageTemplateRequest,
        file: File): Observable<WhatsappMessageTemplate> {

        const endpoint = `${environment.basePath}/message-templates/update/${messageTemplateId}`;

        const formData = new FormData();
        formData.append('template', JSON.stringify(template));
        if (file) {
            formData.append('file', file);
        }

        const headers = new HttpHeaders();
        headers.append('Accept', 'application/json');

        return this.httpClient.put<any>(endpoint, formData, { headers });
    }

    deleteTemplate(templateName: string): Observable<any> {
        const endpoint = `${environment.basePath}/message-templates/delete`;
        let templateRequest: WhatsappMessageTemplateRequest = { name: templateName };
        const httpOptions = {
            headers: new HttpHeaders({ 'Content-Type': 'application/json' }), body: templateRequest
        };
        return this.httpClient.delete<WhatsappMessageTemplateRequest>(endpoint, httpOptions);
    }

}