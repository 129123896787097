import { Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges, ViewChild } from "@angular/core";

import { SocketClientService } from "src/services/socket-client.service";
import { DataStorageService } from "src/services/data/data-storage";

import { NoteService } from "src/services/note.service";
import { LabelService } from "src/services/label.service";
import { UserService } from "src/services/user.service";
import { MessageService } from "src/services/message.service";
import { ConversationService } from "src/services/conversation.service";

import { UserInfo } from "src/app/model/user/user-info";
import { SimpleConversation } from "src/app/model/conversation/simple-conversation";
import { Conversation } from "src/app/model/conversation/conversation";
import { Label } from "src/app/model/label/label";

import * as Constants from "src/app/util/constants";
import { ToastrService } from "ngx-toastr";
import { NgxSpinnerService } from "ngx-spinner";
import * as moment from "moment";
import saveAs from "file-saver";

import { FormControl } from "@angular/forms";

import { debounceTime, distinctUntilChanged, switchMap } from "rxjs/operators";
import { EMPTY } from "rxjs";
import { GetConversationsRequest } from "src/app/model/conversation/get-conversations-request";
import { Page } from "src/app/model/page/page";
import { NgbNav } from "@ng-bootstrap/ng-bootstrap";
import { SharedService } from "src/services/shared.service";

@Component({
  selector: "app-chat-conversations",
  templateUrl: "./chat-conversations.component.html",
  styleUrls: ["./chat-conversations.component.scss"],
})
export class ChatConversationsComponent
  implements OnInit, OnChanges, OnDestroy {
  @Output() selectConversationEvent: EventEmitter<Conversation> =
    new EventEmitter();

  @Input() labelChanges: any;
  @Input() changeName: string;
  @Input() companyPhoneNumber: string;
  @Input() companyName: string;
  @Input() archived: any;
  @Input() conversationReaded: any;

  //Constants
  profilePicture: string = Constants.defaultProfilePictureUrl;

  // UI variables
  loading: boolean = false;
  loadingMessage: string;
  showErrorMessage: string = "";
  showSuccessMessage: string = "";

  // Lists
  conversations: SimpleConversation[] = [];

  labels: Label[];
  labelFilter: Label | null = null;
  userFilter: UserInfo | null = null;

  selectedConversation: SimpleConversation;

  subscriptions: any[];
  unreadConversations: number = 0;

  totalPages: number;
  totalElements: number;
  lastPage: boolean = false;
  pageSize: number = 20;
  page: number = 0;

  users: UserInfo[] = [];
  activeTab: string = "all";
  @ViewChild("nav") nav: NgbNav;
  active = 1;
  searchControl = new FormControl();

  constructor(
    private labelService: LabelService,
    private conversationService: ConversationService,
    private userService: UserService,
    private socketService: SocketClientService,
    private dataStorageService: DataStorageService,
    private messageService: MessageService,
    private spinner: NgxSpinnerService,
    private toastr: ToastrService,
    private noteService: NoteService
  ) {
    this.subscriptions = [
      {
        topic: "/user/topic/conversation-marked-as-read",
        handler: (notification: any) => {
          notification = JSON.parse(notification.body);

          const conversationId = notification.conversationId;

          this.markConversationAsRead(conversationId);
          this.countUnreadConversations();
        },
      },
      {
        topic: "/user/topic/new-conversation",
        handler: (notification: any) => {
          let conversationBody = JSON.parse(notification.body);

          this.conversationService
            .getSimpleConversation(conversationBody.conversationId)
            .subscribe((conversation) => {
              conversation.lastMessage.createdAt = moment(
                conversation.lastMessage.createdAt
              )
                .locale("es")
                .format("HH:mm");
              this.conversations.unshift(conversation);
              this.countUnreadConversations();
            });
        },
      },
      {
        topic: "/user/topic/receive-message",
        handler: (messageNotification: any) => {
          let messageBody = JSON.parse(messageNotification.body);

          this.onMessageReceived(messageBody);
          this.countUnreadConversations();
        },
      },
      {
        topic: "/user/topic/expire-conversation",
        handler: (notification: any) => {
          let body = JSON.parse(notification.body);

          for (const conversationId of body.conversationIds) {
            let expired = body.expired;
            let conversation = this.conversations.find(
              (conversation) => conversation.id == conversationId
            );
            if (conversation) {
              conversation.expired = expired;
            }
          }
        },
      },
      {
        topic: "/user/topic/archive-conversation",
        handler: (notification: any) => {
          let body = JSON.parse(notification.body);
          for (const conversationId of body.conversationIds) {
            let archived = body.archived;
            let conversation = this.conversations.find(
              (c) => c.id == conversationId
            );
            if (conversation) {
              conversation.archived = archived;
              this.removeConversationFromList(conversationId);
            }
          }
        },
      },
    ];
  }

  playAudio() {
    let notificationSound = new Audio();

    notificationSound.src = Constants.notificationSoundFile;
    notificationSound.volume = 0.5;
    notificationSound.load();

    notificationSound.play();
  }

  onMessageReceived(messageBody: any) {
    this.messageService
      .getMessage(messageBody.conversationId, messageBody.messageId)
      .subscribe((message) => {
        if (!message.companyMessage) {
          this.playAudio();
        }

        let conversationId = message.conversationId;

        let conversation = this.conversations.find(
          (c) => c.id === conversationId
        );
        let conversationIndex = this.conversations.findIndex(
          (c) => c.id === conversationId
        );

        if (!conversation) {
          this.conversationService
            .getSimpleConversation(conversationId)
            .subscribe((conversation) => {
              this.updateConversation(message, conversation);
              this.conversations.unshift(conversation);
            });
        } else {
          this.updateConversation(message, conversation);

          this.conversations.splice(conversationIndex, 1);
          this.conversations.unshift(conversation);
        }
      });
  }

  markConversationAsRead(conversationId: number) {
    let conversation = this.conversations.find((c) => c.id === conversationId);
    if (conversation) {
      conversation.unreadMessagesCount = 0;
    }
  }

  updateConversation(message: any, conversation: any) {
    message.createdAt = moment(message.createdAt).format("HH:mm");

    conversation.lastMessage = message;

    // si hay una conversación seleccionada
    if (this.selectedConversation != undefined) {
      if (this.selectedConversation.id !== conversation.id) {
        if (!message.companyMessage) conversation.unreadMessagesCount += 1;
      }
      if (this.selectedConversation.expired) {
        this.selectedConversation.expired = false;
      }
    } else {
      if (!message.companyMessage) conversation.unreadMessagesCount += 1;
    }
  }

  ngOnInit() {

    const user = this.dataStorageService.authenticatedUser;

    this.getConversations().subscribe(page => {
      this.processConversationPage(page);
      this.page++;
    });
    this.countUnreadConversations();
    this.fetchUsers();
    if (user) {
      this.socketService
        .connect(user.companyId, this.subscriptions)
        .then(() => { });
    }

    this.labelService.getLabels().subscribe((labels) => {
      this.labels = labels;
    });

    this.initSearchConversations();
  }

  ngOnDestroy() {
    this.socketService.disconnect();
  }

  countUnreadConversations() {
    this.conversationService
      .countUnreadConversations()
      .subscribe((response) => {
        this.unreadConversations = response;
      });
  }

  filterByLabel(labelFilter: Label) {
    if (this.nav.activeId != 1) {
      this.nav.select(1);
      this.activeTab = "all"
    }

    this.labelFilter = labelFilter;
    this.userFilter = null;
    this.conversationService
      .getFilteredConversations(labelFilter.id)
      .subscribe(conversations => {
        this.parseSentTime(conversations);
        this.conversations = conversations;
      });
  }

  filterByUser(userFilter: UserInfo) {
    if (this.nav.activeId != 1) {
      this.nav.select(1);
      this.activeTab = "all"
    }

    this.userFilter = userFilter;
    this.labelFilter = null;
    this.conversationService
      .getUserConversations(userFilter.id)
      .subscribe(conversations => {
        this.parseSentTime(conversations);
        this.conversations = conversations;
      });
  }

  cleanFilters() {
    this.userFilter = null;
    this.labelFilter = null;
    this.page = 0;
    this.getConversations().subscribe(page => {
      this.processConversationPage(page);
      this.page++;
    });
  }

  parseSentTime(conversations: SimpleConversation[]) {
    for (let conversation of conversations) {
      var lastMessage = conversation.lastMessage;

      if (lastMessage) {
        let now = moment();
        let messageTime = moment(lastMessage.createdAt);

        var duration = moment.duration(now.diff(messageTime));

        var hours = duration.asHours();
        var days = duration.asDays();

        if (hours < 24) {
          if (messageTime.weekday() == now.weekday()) {
            lastMessage.createdAt = messageTime.format("HH:mm");
          } else {
            lastMessage.createdAt = "Ayer";
          }
        } else if (hours >= 24 && hours <= 48) {
          lastMessage.createdAt = "Ayer";
        } else if (hours >= 48 && days < 7) {
          var dayName = messageTime.format("dddd");
          dayName.charAt(0).toUpperCase() + dayName.substring(1);

          lastMessage.createdAt = dayName;
        } else if (days >= 7) {
          lastMessage.createdAt = messageTime.format("DD/MM/YYYY");
        }
      }
    }
  }

  activateConversation(simpleConversation: SimpleConversation) {

    let conversationId = simpleConversation.id;

    if (
      this.selectedConversation &&
      this.selectedConversation.id === conversationId
    )
      return;

    this.selectedConversation = simpleConversation;

    if (simpleConversation.expired) {
      simpleConversation.expired = false;
      this.conversationService
        .setExpiredConversation(conversationId, false)
        .subscribe(() => { });
    }

    this.conversationService
      .getConversation(conversationId)
      .subscribe((conversation) => {
        if (!conversation.readed) {
          this.unreadConversations -= 1;
          simpleConversation.unreadMessagesCount = 0;
          simpleConversation.readed = true;
          this.conversationService
            .setReadConversation(conversationId, true, 0)
            .subscribe(() => { });
        }

        this.selectConversationEvent.emit(conversation);
      });
  }

  fetchUsers() {
    this.userService.getUsers().subscribe((users) => {
      this.users = users;
    });
  }

  removeConversationFromList(conversationId: number) {
    let index = this.conversations.findIndex((c) => c.id == conversationId);
    this.conversations.splice(index, 1);
  }

  exportNotes() {
    this.loadingMessage = "Exportando...";
    this.showSuccessMessage = "Notas exportadas correctamente";
    this.spinner.show();
    this.noteService.exportAllNotes().subscribe((blob) => {
      saveAs(blob, "Export.xlsx");
      this.spinner.hide();
      this.showSuccess();
    });
  }

  showSuccess() {
    this.toastr.success(this.showSuccessMessage, "¡Exito!");
  }

  showError() {
    this.toastr.error(this.showErrorMessage, "Error");
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.labelChanges) {
      if (changes.labelChanges.currentValue != undefined) {
        if (changes.labelChanges.currentValue.action) {
          if (
            changes.labelChanges.currentValue.action == "expired_conversation"
          ) {
            this.selectedConversation.expired = false;
          }
        }

        let currentLabel = changes.labelChanges.currentValue;

        if (currentLabel.deleted) {
          let index = this.labels.findIndex(
            (f: any) => f.id === currentLabel.selectedLabel.id
          );
          this.labels.splice(index, 1);
        } else {
          let labelFound: any = this.labels.find(
            (label) => label.id === currentLabel.id
          );
          let index = this.labels.indexOf(labelFound);

          if (labelFound) {
            this.labels.splice(index, 1, currentLabel);
          } else {
            this.labels.unshift(currentLabel);
          }
        }
      }
    }

    if (changes.changeName) {
      if (changes.changeName.currentValue != undefined) {
        if (this.selectedConversation)
          if (this.selectedConversation.contactName)
            this.selectedConversation.contactName =
              changes.changeName.currentValue;
      }
    }

    if (changes.archived) {

      if (changes.archived.currentValue != undefined) {
        let conversationId = changes.archived.currentValue.conversationId;
        let archived = changes.archived.currentValue.archived;
        let conversationFound = this.conversations.find(
          (c) => c.id == conversationId
        );
        if (conversationFound != undefined) {
          this.removeConversationFromList(conversationId);
        }

        if (!archived && this.activeTab == "all") {
          let conversationFound = this.conversations.find(c => c.id == conversationId);
          if (!conversationFound) {
            this.conversationService
              .getSimpleConversation(conversationId)
              .subscribe((conversation: SimpleConversation) => {
                this.parseSentTime([conversation]);
                this.conversations.unshift(conversation);
              });
          }
        }
      }
    }

    if (changes.conversationReaded) {
      if (changes.conversationReaded.currentValue != undefined) {
        let conversationId =
          changes.conversationReaded.currentValue.conversationId;
        let conversationReaded = changes.conversationReaded.currentValue.read;
        let conversationFound = this.conversations.find(
          (c) => c.id == conversationId
        );
        this.conversations.forEach((conversation) => {
          if (conversation.id === conversationFound?.id) {
            conversation.readed = conversationReaded;
            if (!conversationReaded) {
              this.unreadConversations += 1;
            } else {
              if (this.unreadConversations > 0) {
                this.unreadConversations -= 1;
              }
            }
          }
        });
      }
    }
  }

  initSearchConversations() {

    this.searchControl.valueChanges
      .pipe(
        debounceTime(250),
        distinctUntilChanged(),
        switchMap(query => {
          if (query.trim() === '') {
            this.page = 0;
            return this.getConversations();
          }
          return this.searchConversations();
        })
      )
      .subscribe(response => {
        this.conversations = [];
        if (response.hasOwnProperty("content")) {
          const page = response as Page<SimpleConversation>;
          this.processConversationPage(page);
          this.page++;
        } else {
          const conversations = response as SimpleConversation[];
          this.parseSentTime(conversations);
          this.conversations = conversations;
        }
      });
  }

  filterConversationsByGroup(tab: string) {

    if (this.labelFilter || this.userFilter) {
      this.nav.select(1);
      this.activeTab = tab;

    } else {
      this.activeTab = tab;
      this.conversations = [];
      this.page = 0;
      this.getConversations().subscribe(page => {
        this.processConversationPage(page);
        this.page++;
      });
    }

  }

  processConversationPage(page: Page<SimpleConversation>) {

    const conversations = page.content;

    this.parseSentTime(conversations);
    this.conversations = conversations;

    this.totalPages = page.totalPages;
    this.totalElements = page.totalElements;
    this.lastPage = page.last;
  }

  processConversationPageOnScroll(page: Page<SimpleConversation>) {

    const conversations = page.content;

    this.parseSentTime(conversations);
    this.conversations = this.conversations.concat(conversations);

    this.totalPages = page.totalPages;
    this.totalElements = page.totalElements;
    this.lastPage = page.last;
  }

  searchConversations() {

    let query: string = this.searchControl.value;

    if (query && query.length > 0)
      query = query.trim().toLowerCase();

    const request: GetConversationsRequest = {
      query: query,
      archived: this.activeTab === 'archived',
      unread: this.activeTab === 'unread',
      labelId: this.labelFilter ? this.labelFilter.id : null,
      userId: this.userFilter ? this.userFilter.id : null
    };

    return this.conversationService
      .searchConversations(request);

  }

  getConversations() {

    let query: string = this.searchControl.value;

    if (query && query.length > 0)
      query = query.trim().toLowerCase();

    const request: GetConversationsRequest = {
      query: query,
      archived: this.activeTab === 'archived',
      unread: this.activeTab === 'unread',
      labelId: this.labelFilter ? this.labelFilter.id : null,
      userId: this.userFilter ? this.userFilter.id : null
    };

    return this.conversationService
      .getConversationsNew(request, this.page, this.pageSize);
  }

  onScroll() {
    //if (this.labelFilter || this.userFilter) return;
    //if (this.activeTab == "Unread") return;
    if (!this.lastPage && this.page <= this.totalPages) {
      if (!this.searchControl.value) {
        this.getConversations().subscribe(page => {
          this.processConversationPageOnScroll(page);
          this.page++;
        });
      }
    }
  }

  // Template methods
  getLastMessageContent(conversation: SimpleConversation): string {

    let lastMessage: any = conversation.lastMessage;

    switch (lastMessage.type) {
      case 'text':
      case 'template':
        return lastMessage.content;
      default:
        return lastMessage.type;
    }

  }

  getConversationFontWeight(conversation: SimpleConversation): number {
    return conversation.unreadMessagesCount > 0 ? 800 : 600;
  }

  getConversationChannelIcon(conversation: SimpleConversation): string {
    switch (conversation.channel) {
      case Constants.whatsAppChannel:
        return 'ri-whatsapp-fill conversation-channel text-whatsapp font-18';
      case Constants.whatsAppChannel:
        return 'ri-messenger-fill conversation-channel text-messenger font-18';
      default:
        return '';
    }
  }

  getConversationAvatar(conversation: SimpleConversation): string {
    if (conversation.profilePictureUrl === null)
      return Constants.defaultProfilePictureUrl;
    return conversation.profilePictureUrl;
  }

  isConversationSelected(conversation: SimpleConversation): boolean {
    return this.selectedConversation &&
      this.selectedConversation.id == conversation.id;
  }
  // Template methods

}
