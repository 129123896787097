import { Injectable } from '@angular/core';
import { OAuthService } from 'angular-oauth2-oidc';
import { HttpClient } from '@angular/common/http';
import { Observable, Subject } from 'rxjs';
import { environment } from 'src/environments/environment';
import { authConfig } from '../config/auth-config';
import { UserRequest } from 'src/app/model/user/user-request';
import { User } from 'src/app/model/user/user';

@Injectable()
export class AuthService {
    authenticated: Subject<boolean> = new Subject();
    private accessTokenExpirationTimer: any;

    constructor(private oauthService: OAuthService, private httpClient: HttpClient) {
        this.configureOAuthService();
    }

    configureOAuthService() {
        if (!this.oauthService.clientId) {
            this.oauthService.configure(authConfig);
            if (this.isAuthenticated()) {
                this.scheduleAccessTokenExpiration();
            }
        }
    }

    isAuthenticated(): boolean {
        let authenticated = this.oauthService.hasValidAccessToken();
        this.authenticated.next(authenticated);
        return authenticated;
    }

    login(email: string, password: string): Promise<any> {
        let response = this.oauthService.fetchTokenUsingPasswordFlow(email, password);
        response.then(() => {
            this.scheduleAccessTokenExpiration();
        });
        return response;
    }

    checkToken(accessToken: string | null): Observable<any> {
        const url = `${environment.authServerUrl.replace('token', 'check_token')}?token=${accessToken}`;
        return this.httpClient.post<any>(url, null);
    }

    logOut() {
        this.clearAccessTokenExpirationTimer();
        this.authenticated.next(false);
        this.oauthService.logOut();
    }

    private scheduleAccessTokenExpiration(): void {
        const expiresIn = this.oauthService.getAccessTokenExpiration() - Date.now() - (5 * 1000); // Refresh token one minute before expiration
        this.accessTokenExpirationTimer = setTimeout(() => {
            this.refreshAccessToken();
        }, expiresIn);
    }

    private refreshAccessToken(): void {
        this.oauthService.refreshToken().then(() => {
            this.scheduleAccessTokenExpiration();
        });
    }

    private clearAccessTokenExpirationTimer(): void {
        if (this.accessTokenExpirationTimer) {
            clearTimeout(this.accessTokenExpirationTimer);
            this.accessTokenExpirationTimer = null;
        }
    }

    public get accessToken(): string {
        return this.oauthService.getAccessToken();
    }
}