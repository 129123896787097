import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';

// services
import { EventService } from 'src/app/core/service/event.service';

// types
import { LayoutEventType } from 'src/app/core/constants/events';
import { User } from 'src/app/core/models/auth.models';
import { AppsItem } from '../../shared/models/apps.model';
import { Language } from '../../shared/models/language.model';
import { NotificationItem } from '../../shared/models/notification.model';
import { ProfileOptionItem } from '../../shared/models/profileoption.model';
import { SearchResultItem, SearchUserItem } from '../../shared/models/search.model';

// layout constants
import { LayoutType, SideBarWidth } from '../models/layout.model';
import { AuthService } from 'src/app/core/service/auth.service';
import { DataStorageService } from 'src/services/data/data-storage';
import { UserInfo } from 'src/app/model/user/user-info';
import { UserService } from 'src/services/user.service';

@Component({
  selector: 'app-topbar',
  templateUrl: './topbar.component.html',
  styleUrls: ['./topbar.component.scss']
})
export class TopbarComponent implements OnInit {


  @Input() hideLogo: boolean = false;
  @Input() cssClasses: string = '';
  @Input() layoutType: string = '';

  notificationList: NotificationItem[] = [];
  languages: Language[] = [];
  apps: AppsItem[] = [];
  profileOptions: ProfileOptionItem[] = [];
  selectedLanguage?: Language;
  searchResults: SearchResultItem[] = [];
  searchUsers: SearchUserItem[] = [];
  loggedInUser: UserInfo;
  topnavCollapsed: boolean = false;

  @Output() settingsButtonClicked = new EventEmitter<boolean>();
  @Output() mobileMenuButtonClicked = new EventEmitter();
  constructor (
    private dataStorage: DataStorageService,
    private eventService: EventService,
    private authService: AuthService,
    private userService: UserService,
    public router: Router
  ) { }

  currentUser: UserInfo;

  ngOnInit() {
    this._fetchProfileOptions();
    this.userService.userInfo().subscribe(response => { 
      this.loggedInUser = response;
    });
  }

  _fetchProfileOptions() {
    this.profileOptions = [
      {
        label: 'Cerrar sesión',
        icon: 'mdi mdi-logout',
        redirectTo: '/account/logout',
      },
    ];
  }

  toggleRightSidebar() {
    this.settingsButtonClicked.emit();
  }

  toggleSidebarWidth() {
    switch (this.layoutType) {
      case LayoutType.LAYOUT_VERTICAL:
        if (document.body.hasAttribute('data-leftbar-compact-mode') && document.body.getAttribute('data-leftbar-compact-mode') === 'condensed') {
          this.eventService.broadcast(LayoutEventType.CHANGE_LEFT_SIDEBAR_TYPE, SideBarWidth.SIDEBAR_WIDTH_FIXED);
        } else {
          this.eventService.broadcast(LayoutEventType.CHANGE_LEFT_SIDEBAR_TYPE, SideBarWidth.SIDEBAR_WIDTH_CONDENSED);
        }
        break;

      case LayoutType.LAYOUT_FULL:
        document.body.classList.toggle('hide-menu');
        document.body.classList.toggle('sidebar-enable');
    }

  }

  toggleMobileMenu(event: any) {
    this.topnavCollapsed = !this.topnavCollapsed;
    event.preventDefault();
    this.mobileMenuButtonClicked.emit();
  }

  onOptionClick(event: any, route?: string) {
    if (route) {
      if (route === '.') {
        event.preventDefault();
      } else {
        this.authService.logOut();
        this.router.navigateByUrl(route);
      }
    }
  }

}
