import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Observable } from 'rxjs';

import { OAuthService } from 'angular-oauth2-oidc';

@Injectable()
export class TokenInterceptor implements HttpInterceptor {

    constructor(private oauthService: OAuthService) {}

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

        let accessToken: string = this.oauthService.getAccessToken();

        const ignoreAuthorization = request.headers.get('Ignore-Authorization');

        if (ignoreAuthorization)
            return next.handle(request);

        if (accessToken) {

            if (request.url.includes("/oauth/token")) {
                request = request.clone({
                    setHeaders: {
                        Authorization: `Basic ${btoa(`${this.oauthService.clientId}:${this.oauthService.dummyClientSecret}`)}`
                    }
                });
            } else {
                request = request.clone({
                    setHeaders: {
                        Authorization: `Bearer ${accessToken}`
                    }
                });
            }
        }
        return next.handle(request);
    }
}