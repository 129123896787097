import { AfterViewInit, Component, ElementRef, Input, OnChanges, OnInit, SimpleChanges, TemplateRef, ViewChild, ViewChildren } from '@angular/core';
import { EventService } from 'src/app/core/service/event.service';

// type
import { LayoutEventType } from 'src/app/core/constants/events';

//  layout constants
import { LayoutColor, LayoutWidth, SideBarTheme, SideBarWidth } from '../../shared/models/layout.model';
import { CompanyConfigurationService } from 'src/services/company/company-configuration.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { FeedbackService } from 'src/services/feedback.service';
import { NavigationEnd, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { MessageService } from 'src/services/message.service';
import { Template } from 'src/app/model/message/template';
import { ChatConversationsComponent } from 'src/app/apps/chat/chat-conversations/chat-conversations.component';
import { FeedbackRequest } from 'src/app/model/feedback/feedback-request';
import { File } from "src/app/model/file/file";
import { v4 as uuidv4 } from 'uuid';
import { GetPresignedUrlRequest } from 'src/app/model/media/get-presigned-url-request';
import { MediaFeedbackRequest } from 'src/app/model/feedback/media-feedback-request';
import { MediaService } from 'src/services/media.service';
import { AuthService } from 'src/app/core/service/auth.service';
import { WhatsappMessageTemplate } from 'src/app/model/whatsapp-message-template/whatsapp-message-template';
import { WhatsappMessageTemplateService } from 'src/services/whatsapp-message-template.service';

@Component({
  selector: 'app-vertical-layout',
  templateUrl: './layout.component.html',
  styleUrls: ['./layout.component.scss']
})
export class LayoutComponent implements OnInit, AfterViewInit, OnChanges {


  @Input() layoutWidth!: LayoutWidth;
  @Input() sidebarTheme!: SideBarTheme;
  @Input() sidebarType!: SideBarWidth;
  @Input() layoutColor!: LayoutColor;
  @ViewChild(ChatConversationsComponent) conversation: ChatConversationsComponent;

  saveFeedForm: FormGroup;
  broadcastForm: FormGroup;
  sendTemplateForm: FormGroup;

  template: Template;
  reRender: boolean = true;
  alertShow: boolean = false;
  showAlert: boolean = false;
  templates: any[] = [];
  currentRoute: string;
  showErrorMessage: string = ""
  showSuccessMessage: string = ""
  attachedFiles: any[] = [];
  files: any[] = [];
  commitmentInput: string;
  @ViewChild('fileInput')
  fileInput: ElementRef;

  @ViewChild("commitment", { static: true }) commitment: ElementRef;
  modalRef: any;

  constructor(
    private authService: AuthService,
    private router: Router,
    private modalService: NgbModal,
    private fb: FormBuilder,
    private toastr: ToastrService,
    private mediaService: MediaService,
    private feedbackService: FeedbackService,
    private whatsappMessageTemplateService: WhatsappMessageTemplateService,
    private messageService: MessageService,
    private eventService: EventService,
    private companyConfigurationService: CompanyConfigurationService
  ) {
    if (!this.authService.isAuthenticated())
      this.router.navigate(['/account/login']);

    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        this.currentRoute = event.url;
      }
    });
  }

  ngOnInit() {
    this.saveFeedForm = this.fb.group({
      description: ['', Validators.required]
    });
    this.broadcastForm = this.fb.group({
      contactName: ['', Validators.required],
      phoneNumber: ['', Validators.required],
      templateToSearch: ['', Validators.required],
      inputs: this.fb.array([])
    });

    this.fetchDataTemplates();
    this.currentRoute = this.router.url;

  }

  templatesList: WhatsappMessageTemplate[] = [];
  allTemplates: WhatsappMessageTemplate[] = [];
  selectedTemplate: any;
  showFileHeaderInput: boolean = false;
  selectedOption: string = "";
  file: any;
  matches: any;
  selectedFile: any;

  search() {
    let templateToSearch = this.broadcastFormControl.templateToSearch.value

    this.templatesList = templateToSearch ? this.allTemplates.filter(
      t => t.name!.toLowerCase().indexOf(templateToSearch.toLowerCase()) >= 0
    ) : this.allTemplates;
  }

  setCheckoutTemplate(template: WhatsappMessageTemplate) {
    this.broadcastFormControl.templateToSearch.setValue(template.name);
    this.selectedTemplate = template;
    this.inputFieldAsFormArray.controls = [];
    let str = template.body;

    console.log(this.selectedTemplate);

    const header = this.selectedTemplate.components.find((component: any) => component.type === 'HEADER');
    if (header) {
      if (['IMAGE', 'VIDEO', 'DOCUMENT'].includes(header.format)) {
        this.selectedOption = header.format.toLowerCase();
        this.showFileHeaderInput = true;
      }
    }

    let regex = /{{\d+}}/g;
    this.matches = str.match(regex);
    if (this.matches != null)
      for (let i = 0; i < this.matches.length; i++) {
        this.addControl();
      }
  }

  input(): any {
    return this.fb.group({
      input: ['', Validators.required],
    });
  }

  addControl() {
    this.inputFieldAsFormArray.push(this.input());
  }


  getSelectedFileTypeLabel(): string {
    switch (this.selectedOption) {
      case 'image':
        return 'de imagen (JPG, PNG)';
      case 'video':
        return 'de video (MP4)';
      case 'document':
        return 'de video (PDF)';
      default:
        return '';
    }
  }


  fetchDataTemplates() {
    this.whatsappMessageTemplateService.getMessageTemplates().subscribe(response => {
      response.forEach(template => {
        const body = template.components.find(element => element.type === 'BODY');
        if (body != undefined)
          template.body = body?.text;
        if (template.status == 'APPROVED') {
          this.templatesList.push(template);
          this.allTemplates.push(template);
        }
      });

    })
  }

  get inputFieldAsFormArray(): any {
    return this.broadcastForm.get('inputs') as FormArray;
  }
  get broadcastFormControl() { return this.broadcastForm.controls; }


  ngOnDestroy() {
    localStorage.removeItem('modalInit');
  }

  fetchConfiguration() {
    this.companyConfigurationService.getCompanyConfiguration().subscribe((response: any) => {
      if (response.length == 0) {
        this.alertShow = true;
      }
    });
  }

  ngAfterViewInit() {
    document.body.setAttribute('data-layout', 'vertical');
    if (localStorage.getItem('modalInit') == null || localStorage.getItem('modalInit') == 'false') {
      this.openModal(this.commitment);
    }
    this.fetchConfiguration();
  }

  ngOnChanges(changes: SimpleChanges) {
    this.changeLayoutConfig();
    if (changes["layoutWidth"]) {
      this._setRerender();
    }
  }

  _setRerender = () => {
    this.reRender = false;
    setTimeout(() => {
      this.reRender = true;
    }, 0.05);
  }

  onAcceptCommitment() {
    if (this.commitmentInput === "ACEPTO") {
      this.modalRef.close();
      this.showAlert = false;
      localStorage.setItem('modalInit', 'true');
    } else {
      this.showAlert = true;
    }
  }

  openModal(commitment: any) {
    this.modalRef = this.modalService.open(commitment, { backdrop: 'static', keyboard: false, windowClass: 'bg-pink', size: 'lg' });
    localStorage.setItem('modalInit', 'false');
  }

  changeLayoutConfig() {
    // boxed vs fluid
    document.body.setAttribute('data-layout-mode', this.layoutWidth);

    // layout color
    document.body.setAttribute('data-layout-color', this.layoutColor);

    // left sidebar theme
    document.body.setAttribute('data-leftbar-theme', this.sidebarTheme);

    // left sidebar type
    document.body.setAttribute('data-leftbar-compact-mode', this.sidebarType);

  }

  onSettingsButtonClicked() {
    this.eventService.broadcast(LayoutEventType.SHOW_RIGHT_SIDEBAR, true);
  }

  onToggleMobileMenu() {
    document.body.classList.toggle('sidebar-enable');
  }

  get saveFeedValues() { return this.saveFeedForm.controls; }
  get formValues() { return this.broadcastForm.controls; }


  open(content: TemplateRef<NgbModal>) {
    const modal = this.modalService.open(content)
    modal.hidden.subscribe(() => {
      this.broadcastForm.reset();
    });
  }

  showSuccess() { this.toastr.success(this.showSuccessMessage, '¡Exito!'); }
  showError() { this.toastr.error(this.showErrorMessage, 'Error'); }
  saveFeedback() {
    if (this.saveFeedForm.valid) {
      this.showSuccessMessage = "Feedback creado correctamente";
      let feedback: FeedbackRequest = {
        conversationId: null,
        description: this.saveFeedValues.description.value,
        type: 'general'
      }

      this.feedbackService.saveFeedback(feedback).subscribe(feedback => {
        this.showSuccess();
        if (this.attachedFiles.length > 0) {
          this.saveMediaFeedback(feedback.id);
        }
      });
      this.modalService.dismissAll();
      this.saveFeedValues.description.reset();
    }
  }

  fetchTemplates() {
    this.messageService.getTemplates().subscribe((response: any) => {
      response.forEach((template: any) => {
        if (template.status === "APPROVED") {
          this.templates.push(template)
        }
      });
    });
  }

  sendTemplateMessage() {
    if (this.broadcastForm.valid) {
      this.showSuccessMessage = "Mensaje enviado correctamente";

      let messageTemplateRequest = {
        messaging_product: 'whatsapp',
        to: this.formValues.phoneNumber.value,
        type: 'template',
        name: this.formValues.contactName.value,
        template: {},
      };

      let templateRequest: any = {
        name: this.selectedTemplate.name,
        language: {
          code: this.selectedTemplate.language
        },
        components: []
      }

      const header = this.selectedTemplate.components.find((component: any) => component.type === 'HEADER');
      const body = this.selectedTemplate.components.find((component: any) => component.type === 'BODY');
      if (header) {
        if (['IMAGE', 'VIDEO', 'DOCUMENT'].includes(header.format)) {
          const headerParameter = {
            type: header.format.toLowerCase(),
            [header.format.toLowerCase()]: {
              link: ""
            }
          };
          templateRequest.components.push({
            type: 'header',
            parameters: [headerParameter]
          });
        }
      }

      if (body) {
        const bodyParameters = [];
        if (this.matches != null) {
          for (let i = 0; i < this.matches.length; i++) {
            const parameter = {
              type: "text",
              text: this.inputFieldAsFormArray.controls[i].controls.input.value
            };
            bodyParameters.push(parameter);
          }
        }

        if (bodyParameters.length > 0) {
          templateRequest.components.push({
            type: 'body',
            parameters: bodyParameters
          });
        }
      }

      messageTemplateRequest.template = templateRequest;

      this.messageService.sendTemplate(messageTemplateRequest, this.file).subscribe(response => {
        this.showSuccess();

      });
      this.modalService.dismissAll();
    }

  }

  handleFileUpload(event: any) {
    this.file = event.target.files[0];
  }

  onFilePicked(event: Event) {

    const eventTarget = (event.target as HTMLInputElement);

    if (eventTarget.files != null) {
      this.createFileObject(eventTarget.files);
    }

  }

  onFileRemoved(file: File) {
    let index = this.attachedFiles.findIndex((f: any) => f.name === file.name);
    this.attachedFiles.splice(index, 1);
    this.fileInput.nativeElement.value = "";
  }

  createFileObject(files: any) {

    for (let i = 0; i < files.length; i++) {
      const file = files[i];
      let currentFile: File = {
        id: i,
        identifier: uuidv4(),
        name: file.name,
        size: this.parseBytes(file.size),
        type: file.type,
        file: file
      };

      this.attachedFiles.push(currentFile);

    }
  }

  saveMediaFeedback(feedbackId: number) {

    this.attachedFiles.forEach(attachedFile => {
      let presignedRequest: GetPresignedUrlRequest = {
        fileName: attachedFile.name,
        contentType: attachedFile.type
      };

      this.mediaService.getSignedUrl(presignedRequest).subscribe(signedUrlResponse => {
        this.mediaService.uploadMedia(signedUrlResponse.signedUrl, attachedFile.file).subscribe(uploadMediaResponse => {

          let mediaFeedbackRequest: MediaFeedbackRequest = {
            feedbackId: feedbackId,
            fileName: attachedFile.name,
            key: signedUrlResponse.key
          }
          this.feedbackService.saveMediaFeedback(mediaFeedbackRequest).subscribe(response => {
            this.attachedFiles = [];
          });
        });
      });

    });


  }

  parseBytes(size: any) {
    const decimals = 2;
    const k = 1024
    const dm = decimals < 0 ? 0 : decimals
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']
    const i = Math.floor(Math.log(size) / Math.log(k))
    return `${parseFloat((size / Math.pow(k, i)).toFixed(dm))} ${sizes[i]}`
  }


}
