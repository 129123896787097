import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Message } from 'src/app/model/message/message';
import { MessengerMessageRequest } from 'src/app/model/message/messenger-message-request';
import { WhatsAppMessageRequest } from '../app/model/message/message-request';
import { environment } from '../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class MessageService {

  httpHeaders: HttpHeaders;

  constructor(protected httpClient: HttpClient, private http: HttpClient) {
    this.httpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
    });
  }

  sendWhatsAppMessage(message: WhatsAppMessageRequest): Observable<Message> {
    const endpoint = `${environment.basePath}/messages/send-whatsapp-message`;
    return this.httpClient.post<Message>(endpoint, message);
  }

  sendMessengerMessage(message: MessengerMessageRequest): Observable<Message> {
    const endpoint = `${environment.basePath}/messages/send-messenger-message`;
    return this.httpClient.post<Message>(endpoint, message);
  }

  getMessages(conversationId: any): Observable<Message[]> {
    const endpoint = `${environment.basePath}/messages/${conversationId}/get-messages`;
    return this.httpClient.get<Message[]>(endpoint);
  }

  getMessage(conversationId: any, messageId: any): Observable<Message> {
    const endpoint = `${environment.basePath}/messages/${conversationId}/get-message/${messageId}`;
    return this.httpClient.get<Message>(endpoint);
  }

  getMessagesWithReferral(conversationId: any): Observable<Message[]> {
    const endpoint = `${environment.basePath}/messages/${conversationId}/get-messages-with-referral`;
    return this.httpClient.get<Message[]>(endpoint);
  }

  getMessageByMessageIdentifier(messageRepliedId: string): Observable<Message> {
    let params = new HttpParams()
      .append('messageRepliedId', messageRepliedId)
    const endpoint = `${environment.basePath}/messages/get-message-by-identifier`;
    return this.httpClient.get<Message>(endpoint, {
      headers: this.httpHeaders,
      params: params
    });
  }

  getPageMessages(conversationId: any, page: number, size: number): Observable<any> {
    let params = new HttpParams()
      .append('page', page.toString())
      .append('size', size.toString());
    const endpoint = `${environment.basePath}/messages/${conversationId}/get-page-messages`;
    return this.httpClient.get<any>(endpoint, {
      headers: this.httpHeaders,
      params: params
    });
  }

  getTemplates(): any {
    const endpoint = `${environment.basePath}/message-templates`;
    return this.httpClient.get<any>(endpoint);
  }

  sendTemplate(template: any, file: File): Observable<any> {
    const endpoint = `${environment.basePath}/messages/send-whatsapp-template-message`;

    const formData = new FormData();
    formData.append('template', JSON.stringify(template));
    if (file) {
      formData.append('file', file);
    }
    const headers = new HttpHeaders();
    headers.append('Content-Type', 'multipart/form-data');

    return this.httpClient.post<any>(endpoint, formData, { headers });
  }

  markMessagesAsRead(messageIdentifiers: string[], conversationId: number): Observable<any> {
    const endpoint = `${environment.basePath}/messages/mark-as-read/${conversationId}`;
    return this.httpClient.post<any>(endpoint, messageIdentifiers);
  }
}
