import { Injectable, Output } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../environments/environment';
import { Feedback } from 'src/app/model/feedback/feedback';
import { FeedbackRequest } from 'src/app/model/feedback/feedback-request';
import { MediaFeedbackRequest } from 'src/app/model/feedback/media-feedback-request';
import { MediaFeedback } from 'src/app/model/feedback/media-feedback';

@Injectable({
  providedIn: 'root',
})
export class FeedbackService {

  httpHeaders: HttpHeaders;

  constructor(protected httpClient: HttpClient) {
    this.httpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
    });
  }

  exportFeedback(conversationId: number): Observable<Blob> {
    const endpoint = `${environment.basePath}/feedback/${conversationId}/export`;
    return this.httpClient.get(endpoint, {
      responseType: 'blob'
    })
  }

  exportAllFeedback(): Observable<Blob> {
    const endpoint = `${environment.basePath}/feedback/export`;
    return this.httpClient.get(endpoint, {
      responseType: 'blob'
    })
  }

  getFeedback(conversationId: number): Observable<Feedback[]> {
    const endpoint = `${environment.basePath}/feedback/${conversationId}/get-feedback`;
    return this.httpClient.get<Feedback[]>(endpoint);
  }

  getAllFeedbacks(page: number, size: number, search: string): Observable<any> {
    let params = new HttpParams()
      .append('page', page.toString())
      .append('size', size.toString());
    const endpoint = `${environment.basePath}/feedback/get-feedback`;
    return this.httpClient.get<any>(endpoint, {
      headers: this.httpHeaders,
      params: params
    });
  }

  getAllFeedbacksByUser(userId: number, page: number, size: number): Observable<any> {
    let params = new HttpParams()
      .append('userId', userId)
      .append('page', page.toString())
      .append('size', size.toString());
    const endpoint = `${environment.basePath}/feedback/get-feedbacks-by-user`;
    return this.httpClient.get<any>(endpoint, {
      headers: this.httpHeaders,
      params: params
    });
  }


  getAllFeedbacksByDate(fromDate: string, toDate: string, page: number, size: number): Observable<any> {
    let params = new HttpParams()
      .append('fromDate', fromDate)
      .append('toDate', toDate)
      .append('page', page.toString())
      .append('size', size.toString());
    const endpoint = `${environment.basePath}/feedback/get-feedbacks-by-date`;
    return this.httpClient.get<any>(endpoint, {
      headers: this.httpHeaders,
      params: params
    });
  }

  saveMediaFeedback(feedback: MediaFeedbackRequest): Observable<MediaFeedback> {
    const endpoint = `${environment.basePath}/feedback/media-feedback`;
    return this.httpClient.post<MediaFeedback>(endpoint, feedback);
  }

  getMediaFeedback(feedbackId: number): Observable<any> {
    const endpoint = `${environment.basePath}/feedback/get-media-feedback/${feedbackId}`;
    return this.httpClient.get<MediaFeedback>(endpoint);
  }

  deleteMediaFeedback(feedbackId: number): Observable<any> {
    const endpoint = `${environment.basePath}/feedback/media-feedback/${feedbackId}`;
    return this.httpClient.delete(endpoint);
  }

  saveFeedback(feedback: FeedbackRequest): Observable<Feedback> {
    const endpoint = `${environment.basePath}/feedback`;
    return this.httpClient.post<Feedback>(endpoint, feedback);
  }

  updateFeedback(description: string, feedbackId: number): Observable<Feedback> {
    let params = new HttpParams().set('description', description);
    const endpoint = `${environment.basePath}/feedback/${feedbackId}`;
    return this.httpClient.put<Feedback>(endpoint, null, {
      params: params
    });
  }

  deleteFeedback(feedbackId: number): Observable<any> {
    const endpoint = `${environment.basePath}/feedback/${feedbackId}`;
    return this.httpClient.delete(endpoint);
  }


}
