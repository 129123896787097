import { Injectable } from '@angular/core';
import { UserInfo } from 'src/app/model/user/user-info';

@Injectable()
export class DataStorageService {

    private authenticatedUserKey: string = 'authenticatedUser';

    set authenticatedUser(authenticatedUser: UserInfo | undefined) {
        sessionStorage.setItem(this.authenticatedUserKey, JSON.stringify(authenticatedUser));
    }

    get authenticatedUser(): UserInfo | undefined {
        let authenticatedUser = sessionStorage.getItem(this.authenticatedUserKey);
        if (authenticatedUser == null)
            return undefined;
        return JSON.parse(authenticatedUser) as UserInfo;
    }

    clearAll() {
        sessionStorage.clear();
    }

}
