import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { CompanyConfiguration } from "src/app/model/company/company-configuration";
import { CompanyConfigurationRequest } from "src/app/model/company/company-configuration-request";
import { FacebookPageAuthenticationInfo } from "src/app/model/company/facebook-page-authentication-info";
import { environment } from "src/environments/environment";

@Injectable({
    providedIn: 'root'
})
export class CompanyConfigurationService {

    httpHeaders: HttpHeaders;

    constructor(protected httpClient: HttpClient) {
        this.httpHeaders = new HttpHeaders({
            'Content-Type': 'application/json',
        });
    }

    saveCompanyConfiguration(config: CompanyConfigurationRequest[]): Observable<CompanyConfiguration> {
        const endpoint = `${environment.basePath}/company/configuration`;
        return this.httpClient.post<CompanyConfiguration>(endpoint, config);
    }

    getCompanyConfiguration(): Observable<CompanyConfiguration[]>{
        const endpoint = `${environment.basePath}/company/configuration/all`;
        return this.httpClient.get<CompanyConfiguration[]>(endpoint);
    }

    getFacebookPageTokens(): Observable<FacebookPageAuthenticationInfo[]>{
        const endpoint = `${environment.basePath}/company/configuration/facebook/get-page-tokens`;
        return this.httpClient.get<FacebookPageAuthenticationInfo[]>(endpoint);
    }

    registerFacebookPageTokens(facebookAuthResponse: any): Observable<FacebookPageAuthenticationInfo[]> {
        const endpoint = `${environment.basePath}/company/configuration/facebook/register-page-tokens`;
        return this.httpClient.post<FacebookPageAuthenticationInfo[]>(endpoint, facebookAuthResponse);
    }

    unregisterFacebookPageTokens(): Observable<any> {
        const endpoint = `${environment.basePath}/company/configuration/facebook/unregister-page-tokens`;
        return this.httpClient.post<CompanyConfiguration>(endpoint, null);
    }

}