import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { UserInfo } from 'src/app/model/user/user-info';
import { UserRequest } from 'src/app/model/user/user-request';
import { User } from 'src/app/model/user/user';
import { RoleInfo } from 'src/app/model/user/role-info';


@Injectable({
    providedIn: 'root'
})
export class UserService {
    httpHeaders: HttpHeaders;


    constructor(protected httpClient: HttpClient) {
        this.httpHeaders = new HttpHeaders({
            'Content-Type': 'application/json',
        });
    }

    userInfo(): Observable<UserInfo> {
        const url = `${environment.basePath}/user/info`;
        return this.httpClient.get<UserInfo>(url);
    }

    createUser(user: UserRequest): Observable<UserInfo> {
        const endpoint = `${environment.basePath}/user/create-agent`;
        return this.httpClient.post<UserInfo>(endpoint, user);
    }

    getUsers(): Observable<UserInfo[]> {
        const endpoint = `${environment.basePath}/user/get-users`;
        return this.httpClient.get<UserInfo[]>(endpoint);
    }

    getUsersPage(page: number, size: number): Observable<any> {
        let params = new HttpParams()
            .append('page', page.toString())
            .append('size', size.toString());
        const endpoint = `${environment.basePath}/user/get-users-page`;
        return this.httpClient.get<any>(endpoint, {
            headers: this.httpHeaders,
            params: params
        });
    }

    deleteUser(userId: number): Observable<any> {
        const endpoint = `${environment.basePath}/user/${userId}`;
        return this.httpClient.delete(endpoint);
    }
}