<ngx-spinner bdColor="rgba(0,0,0,0.48)" size="medium" color="#fff" type="line-scale" [fullScreen]="true">
    <p class="text-white">{{loadingMessage}}</p>
</ngx-spinner>

<div class="card p-0 m-0">
    <div class="card-body p-0">
        <ul ngbNav #nav="ngbNav" [(activeId)]="active" class="nav-tabs nav-bordered">

            <!-- Todos -->
            <li [ngbNavItem]="1">
                <a ngbNavLink class="chat-nav-link" (click)="filterConversationsByGroup('all')">
                    Todos
                </a>
                <ng-template ngbNavContent>
                    <ng-container *ngTemplateOutlet="ChatConversations"></ng-container>
                </ng-template>
            </li>

            <!-- No leídos -->
            <li [ngbNavItem]="2">
                <a ngbNavLink class="chat-nav-link" (click)="filterConversationsByGroup('unread')">
                    No leídos ({{unreadConversations}})
                </a>
                <ng-template ngbNavContent>
                    <ng-container *ngTemplateOutlet="ChatConversations"></ng-container>
                </ng-template>
            </li>

            <!-- Archivados -->
            <li [ngbNavItem]="3">
                <a ngbNavLink class="chat-nav-link" (click)="filterConversationsByGroup('archived')">
                    Archivados
                </a>
                <ng-template ngbNavContent>
                    <ng-container *ngTemplateOutlet="ChatConversations"></ng-container>
                </ng-template>
            </li>

            <li [ngbNavItem]="4" class="align-self-center ms-auto">
                <div class="float-end" ngbDropdown>
                    <a ngbDropdownToggle class="arrow-none card-drop" id="chatUserDropdown" href="javascript:void(0)"
                        aria-expanded="false">
                        <i class="mdi mdi-dots-horizontal"></i>
                    </a>
                    <div ngbDropdownMenu aria-labelledby="chatUserDropdown">
                        <span class="dropdown-header">Acciones:</span>
                        <a ngbDropdownItem [routerLink]="'.'" (click)="exportNotes()">Exportar notas</a>
                    </div>
                </div>
            </li>
        </ul>
        <div [ngbNavOutlet]="nav"></div>
    </div>
</div>

<ng-template #ChatConversations>
    <div class="app-search">
        <form class="row m-0 p-0">
            <div class="col-md-11 p-0">
                <div class="mb-2 position-relative">
                    <input type="text" class="form-control" placeholder="Buscar" name="seachUser"
                        [formControl]="searchControl" />
                    <span class="mdi mdi-magnify search-icon"></span>
                </div>
            </div>
            <div class="col-md-1 p-0 ps-1">
                <div ngbDropdown class="text-center">
                    <a ngbDropdownToggle class="arrow-none link-cursor" aria-expanded="false">
                        <i class="mdi mdi-filter-variant h2"></i>
                    </a>
                    <div ngbDropdownMenu style="height: 20rem; width: 13rem; overflow-y: auto;">
                        <span class="dropdown-header">
                            <b>Filtrar por etiquetas:</b>
                        </span>
                        <a ngbDropdownItem class="link-cursor" *ngFor="let labelFilter of labels"
                            (click)="filterByLabel(labelFilter)">
                            <small class="btn badge font-12" [style.background-color]="labelFilter.color">
                                {{labelFilter.content}}
                            </small>
                        </a>
                        <span class="dropdown-header">
                            <b>Filtrar por usuario:</b>
                        </span>
                        <a ngbDropdownItem class="link-cursor" *ngFor="let userFilter of users"
                            (click)="filterByUser(userFilter)">
                            <small class="btn btn-primary badge font-12">
                                {{ userFilter.name }}
                            </small>
                        </a>
                    </div>
                </div>
            </div>
        </form>
        <div class="row">
            <div class="text-end" *ngIf="labelFilter">
                <small (click)="cleanFilters()" class="btn badge font-12" [style.background-color]="labelFilter.color">
                    {{ labelFilter.content }}
                    <i class="mdi mdi-close"></i>
                </small>
            </div>
            <div class="text-end" *ngIf="userFilter">
                <small (click)="cleanFilters()" class="btn badge btn-primary font-12">
                    {{ userFilter.name }}
                    <i class="mdi mdi-close"></i>
                </small>
            </div>
        </div>
    </div>

    <div class="search-results" style="height: calc(100vh - 200px) !important;" infiniteScroll
        [infiniteScrollDistance]="1" [infiniteScrollThrottle]="1" [infiniteScrollDisabled]="false"
        (scrolled)="onScroll()" [scrollWindow]="false">

        <!-- <ngx-simplebar class="card-body py-0" style=""> -->
        <a class="text-body conversation-frame link-cursor" *ngFor="let conversation of conversations"
            (click)="activateConversation(conversation)">

            <div [class.bg-light]="isConversationSelected(conversation)"
                class="d-flex align-items-start ps-1 pt-1 pb-1 pe-2">

                <img [src]="getConversationAvatar(conversation)"
                    class="me-2 rounded-circle img-cover conversation-image" height="48" width="48" alt="img" />

                <i [ngClass]="getConversationChannelIcon(conversation)"></i>

                <div class="w-100 overflow-hidden">

                    <h5 class="m-0 font-14" style="font-weight: 600;"
                        [ngStyle]="{'font-weight': conversation.unreadMessagesCount > 0 ? 900 : 600}">
                        {{conversation.contactName}}
                        <span class="float-end text-muted font-12">
                            {{conversation.lastMessage.createdAt}}
                        </span>
                    </h5>

                    <div class="float-end">

                        <span *ngIf="conversation.expired">
                            <i class="mdi mdi-alert text-warning"></i>
                        </span>

                        <span *ngIf="conversation.unreadMessagesCount > 0"
                            class="badge badge-danger-lighten font-12 text-danger">
                            {{ conversation.unreadMessagesCount }}
                        </span>

                    </div>

                    <div class="float-end">
                        <div *ngIf="!conversation.readed">
                            <span class="font-18 text-danger ms-1">
                                <i class="mdi mdi-record"></i>
                            </span>
                        </div>
                    </div>

                    <p class="mt-1 mb-0 font-14 text-truncate">
                        <span class="w-75" [style.font-weight]="getConversationFontWeight(conversation)">
                            {{ getLastMessageContent(conversation) }}
                        </span>
                    </p>
                </div>
            </div>
        </a>
        <!-- </ngx-simplebar> -->
        <div class="d-flex align-items-start mt-1 p-2 justify-content-center" *ngIf="conversations.length === 0">
            <p class="mt-1 mb-0 text-muted font-14 text-truncate">
                <span class="w-75">Sin resultados</span>
            </p>
        </div>
    </div>
</ng-template>