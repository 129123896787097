<div class="navbar-custom" [class]="cssClasses">
    <div [class]="hideLogo ? '' : 'container-fluid'">
        <a routerLink="/" class="topnav-logo" *ngIf="!hideLogo">
            <span class="topnav-logo-lg">
                <img src="assets/images/logo-light.png" alt="" height="16">
            </span>
            <span class="topnav-logo-sm">
                <img src="assets/images/logo_sm_dark.png" alt="" height="16">
            </span>
        </a>
        <ul class="list-unstyled topbar-menu float-end mb-0">           
            <li class="dropdown notification-list" ngbDropdown>
                <a ngbDropdownToggle class="nav-link nav-user arrow-none me-0" id="profileMenuDropdown"
                    href="javascript:void(0)" aria-expanded="false" *ngIf="loggedInUser">
                    <span class="account-user-avatar">
                        <img src="https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_960_720.png" alt="user-image" class="rounded-circle">
                    </span>
                    <span>
                        <span class="account-user-name">{{loggedInUser.name}}</span>
                        <span class="account-position">{{loggedInUser.email}}</span>
                    </span>
                </a>
                <div ngbDropdownMenu aria-labelledby="profileMenuDropdown"
                    class="dropdown-menu-end dropdown-menu-animated topbar-dropdown-menu profile-dropdown">
                    <div ngbDropdownItem class="dropdown-header noti-title">
                        <h6 class="text-overflow m-0">Bienvenido !</h6>
                    </div>
                    <a *ngFor="let option of profileOptions;" class="notify-item" ngbDropdownItem
                        href="javascript:void(0)" (click)="onOptionClick($event,option.redirectTo)">
                        <i class="{{option.icon}} me-1"></i>
                        <span style="cursor: pointer;">{{option.label}}</span>
                    </a>
                </div>
            </li>
        </ul>
        <button class="button-menu-mobile open-left d-none d-md-block" (click)="toggleSidebarWidth()"
            *ngIf="layoutType==='vertical'">
            <i class="mdi mdi-menu open-left-icon"></i>
        </button>
        <button class="button-menu-mobile open-left disable-btn d-md-none" (click)="toggleMobileMenu($event)"
            *ngIf="layoutType==='vertical'">
            <i class="mdi mdi-menu open-left-icon"></i>
        </button>
        <a class="button-menu-mobile open-left disable-btn" (click)="toggleMobileMenu($event)"
            *ngIf="layoutType==='detached'">
            <div class="lines">
                <span></span>
                <span></span>
                <span></span>
            </div>
        </a>
    </div>
</div>
