import { MenuItem } from '../models/menu.model';

export const MENU: MenuItem[] = [

    { key: 'apps', label: 'Apps', isTitle: true },
    { key: 'apps-chat', label: 'Chat', isTitle: false, icon: 'mdi mdi-chat-outline', link: '/apps/chat' },
    { key: 'apps-analytics', label: 'Analítica', isTitle: false, icon: 'mdi mdi-google-analytics', link: '/apps/analytics' },
    { key: 'apps-quick-response', label: 'Respuestas rápidas', isTitle: false, icon: 'mdi mdi-comment-flash-outline', link: '/apps/chat/quickly-response' },
    {
        key: 'apps-marketing', label: 'Marketing', isTitle: false, icon: 'mdi mdi-storefront-outline', collapsed: true,
        children: [
            { key: 'apps-marketing-broadcast', label: 'Broadcast', icon: 'mdi mdi-message-arrow-left', link: '/apps/marketing/broadcast', parentKey: 'apps-broadcast' },
        ]
    },
    {
        key: 'apps-feedback', label: 'Feedback Intelligence', isTitle: false, icon: 'mdi mdi-brain', collapsed: true,
        children: [
            { key: 'apps-general-feedback', label: 'Feedback', icon: 'uil-circuit', link: '/apps/feedback/general', parentKey: 'apps-feedback' },
            { key: 'apps-tracing-feedback', label: 'Seguimiento', icon: 'mdi mdi-file-search-outline', link: '/apps/feedback/tracing', parentKey: 'apps-feedback' },
        ]
    },
    { key: 'apps-pipeline', label: 'Pipeline', isTitle: false, icon: 'mdi mdi-filter-outline', link: '/apps/pipeline' },
    { key: 'whatsapp-message-template', label: 'Plantillas de mensaje', icon: 'mdi mdi-comment-flash-outline', link: '/apps/management/whatsapp-message-template' },
    {
        key: 'apps-management', label: 'Administración', isTitle: false, icon: 'uil-shield', collapsed: true,
        children: [
            { key: 'management-company-files', label: 'Galería', icon: 'uil-image', link: '/apps/management/company-files', parentKey: 'apps-management' },
            { key: 'management-users', label: 'Usuarios', icon: 'uil-user', link: '/apps/management/users', parentKey: 'apps-management' },
            { key: 'management-users', label: 'Configuración', icon: 'dripicons-gear noti-icon', link: '/apps/management/config', parentKey: 'apps-management' },
        ]
    },

]