<div class="wrapper">
    <!-- left side bar-->
    <app-left-side-bar [hideLogo]="false"></app-left-side-bar>

    <div class="content-page">
        <div class="content">
            <app-topbar (settingsButtonClicked)="onSettingsButtonClicked()" [hideLogo]="true"
                (mobileMenuButtonClicked)="onToggleMobileMenu()" layoutType="vertical"></app-topbar>
            <div class="container-fluid" style="height: 0;" *ngIf="reRender">
                <router-outlet></router-outlet>
                <div *ngIf="alertShow == true" class="toast-container position-absolute p-3 mt-5 top-0 end-0"
                    id="toastPlacement">
                    <ngb-toast [autohide]="false" class="mb-4" #custom1="ngbToast">
                        <ng-template ngbToastHeader>
                            <strong class="me-auto ">¡Atención!</strong>
                        </ng-template>
                        <p> Se requiere agregar los valores de configuración para el correcto funcionamiento de la
                            aplicación</p>
                        <div class="mt-2 pt-2">
                            <button type="button" class="btn btn-primary btn-sm me-md-1" (click)="custom1.hide()"
                                routerLink="/apps/management/config">
                                Tomar acción
                            </button>
                        </div>
                    </ngb-toast>
                </div>
            </div>
        </div>
    </div>
</div>

<div ngbDropdown #dropTest="ngbDropdown" placement="top-end">
    <button type="button" class="bg-dark rounded-circle add-global-button" ngbDropdownToggle
        *ngIf="currentRoute != '/apps/feedback/general'">
        <i class="mdi mdi-plus text-white"></i>
    </button>
    <div ngbDropdownMenu aria-labelledby="dropdownBasic" style="max-width:20rem; max-height: 20rem;"
        class="overflow-hidden">
        <a role="button" ngbDropdownItem class="p-2" (click)="open(createFeedback)">
            <p class="text-truncate m-0">Agregar nuevo feedback</p>
        </a>
        <a role="button" ngbDropdownItem class="p-2" (click)="open(sendTemplate)">
            <p class="text-truncate m-0">Enviar nuevo mensaje</p>
        </a>
    </div>
</div>

<ng-template #createFeedback let-modal>
    <div class="modal-header">
        <h5 class="modal-title" id="exampleModalLabel">Agregar nuevo feedback</h5>
        <button type="button" class="btn-close" aria-label="Close" (click)="modal.dismiss('Cross click')"></button>
    </div>
    <form [formGroup]="saveFeedForm" #saveFeed="ngForm" (ngSubmit)="saveFeedback()">
        <div class="modal-body">
            <div class="mb-3">
                <label for="description" class="form-label">Descripción</label>
                <textarea class="form-control" id="description" rows="4"
                    [ngClass]="{'is-invalid': saveFeed.submitted && saveFeedValues.description.invalid}"
                    formControlName="description" placeholder="Ingresa la descripción del feedback">
                </textarea>
                <div *ngIf="saveFeed.submitted && saveFeedValues.description.invalid" class="invalid-feedback">
                    <div *ngIf="saveFeedValues.description.errors?.required">Descripción requerida.</div>
                </div>
            </div>
            <div class="mb-3">
                <label for="formFileMultiple" class="form-label">Adjuntos</label>
                <label>
                    <input #fileInput type="file" style="display: none;" multiple (change)="onFilePicked($event)">
                    <a><i class="uil uil-paperclip mx-2"></i></a>
                </label>
            </div>
            <div class="row">
                <div *ngIf="attachedFiles.length == 0">
                    <p class="text-center">No hay archivos adjuntos</p>
                </div>
                <div class="col-sm-2 py-1 ms-2 card align-items-center" *ngFor="let file of attachedFiles">
                    <i class="mdi mdi-file p-0 m-0 h3"></i>
                    <span (click)="onFileRemoved(file)"
                        class="position-absolute top-0 start-100 translate-middle badge rounded-pill bg-danger">
                        <i class="mdi mdi-close"></i></span>
                    <small class="col-sm-12 text-truncate text-center">
                        {{file.name}}
                    </small>
                    <small class="col-sm-12  text-center">
                        {{file.size}}
                    </small>
                </div>
            </div>
        </div>
        <div class="modal-footer">
            <button type="button" class="btn btn-light" (click)="modal.close('')">Cerrar</button>
            <button type="submit" class="btn btn-primary">Guardar feedback</button>
        </div>
    </form>
</ng-template>
<!-- 
<ng-template #12 let-modal>
    <div class="modal-header">
        <h5 class="modal-title" id="exampleModalLabel">Enviar nuevo mensaje</h5>
        <button type="button" class="btn-close" aria-label="Close" (click)="modal.dismiss('Cross click')"></button>
    </div>
    <form [formGroup]="sendTemplateForm" #sendTemplate="ngForm" (ngSubmit)="sendTemplateMessage()">
        <div class="modal-body">
            <div class="mb-3">
                <label for="contactName" class="form-label">Nombre del contacto</label>
                <input class="form-control" type="contactName" id="contactName" required
                    placeholder="Ingresa el nombre del contacto" formControlName="contactName"
                    [ngClass]="{'is-invalid': sendTemplate.submitted && formValues.contactName.invalid}">
                <div *ngIf="sendTemplate.submitted && formValues.contactName.invalid" class="invalid-feedback">

                    <div *ngIf="formValues.contactName.errors?.required">
                        Nombre de contacto requerido.
                    </div>
                    <div *ngIf="formValues.contactName.errors?.pattern">
                        Ingresa un nombre de contacto valido.
                    </div>

                </div>
            </div>

            <div class="mb-3">
                <label for="phoneNumber" class="form-label">Número de télefono</label>
                <input class="form-control" type="phoneNumber" id="phoneNumber" required
                    placeholder="Ingresa tu número de télefono" formControlName="phoneNumber"
                    [ngClass]="{'is-invalid': sendTemplate.submitted && formValues.phoneNumber.invalid}">
                <div *ngIf="sendTemplate.submitted && formValues.phoneNumber.invalid" class="invalid-feedback">

                    <div *ngIf="formValues.phoneNumber.errors?.required">
                        Número de télefono requerido.
                    </div>
                    <div *ngIf="formValues.phoneNumber.errors?.pattern">
                        Ingresa un número de télefono valido.
                    </div>

                </div>
            </div>

            <div class="mb-3">
                <label for="template" class="form-label">Plantilla</label>
                <select class="form-select form-select-md" aria-label=".form-select-md" id="template"
                    [ngClass]="{'is-invalid': sendTemplate.submitted && formValues.template.invalid}"
                    formControlName="template" required>
                    <option hidden value="" disabled selected>Selecciona una opción</option>
                    <option *ngFor="let template of templates" [ngValue]="template">{{template.name}}</option>
                </select>
                <div *ngIf="sendTemplate.submitted && formValues.template.invalid" class="invalid-feedback">
                    <div *ngIf="formValues.template.errors?.required">Selecciona una opción.</div>
                </div>
            </div>
        </div>
        <div class="modal-footer">
            <button type="button" class="btn btn-light" (click)="modal.close('')">Cerrar</button>
            <button type="submit" class="btn btn-primary">Enviar mensaje</button>
        </div>
    </form>
</ng-template> -->

<ng-template #sendTemplate let-modal>
    <div class="modal-header modal-colored-header bg-primary">
        <h4 class="modal-title" id="exampleModalLabel">Enviar broadcast</h4>
        <button type="button" class="btn-close btn-close-white" aria-label="Close"
            (click)="modal.dismiss('Cross click')"></button>
    </div>
    <div class="p-1">
        <div class="modal-body px-3 pt-3 pb-0">
            <form action="" [formGroup]="broadcastForm" #messageBroadcast="ngForm" (ngSubmit)="sendTemplateMessage()">
                <div class="mb-3">
                    <label for="contactName" class="form-label">Nombre del contacto</label>
                    <input class="form-control" type="contactName" id="contactName" required
                        placeholder="Ingresa el nombre del contacto" formControlName="contactName"
                        [ngClass]="{'is-invalid': messageBroadcast.submitted && formValues.contactName.invalid}">
                    <div *ngIf="messageBroadcast.submitted && formValues.contactName.invalid" class="invalid-feedback">

                        <div *ngIf="formValues.contactName.errors?.required">
                            Nombre de contacto requerido.
                        </div>
                        <div *ngIf="formValues.contactName.errors?.pattern">
                            Ingresa un nombre de contacto valido.
                        </div>

                    </div>
                </div>

                <div class="mb-3">
                    <label for="phoneNumber" class="form-label">Número de télefono</label>
                    <input class="form-control" type="phoneNumber" id="phoneNumber" required
                        placeholder="Ingresa tu número de télefono" formControlName="phoneNumber"
                        [ngClass]="{'is-invalid': messageBroadcast.submitted && formValues.phoneNumber.invalid}">
                    <div *ngIf="messageBroadcast.submitted && formValues.phoneNumber.invalid" class="invalid-feedback">

                        <div *ngIf="formValues.phoneNumber.errors?.required">
                            Número de télefono requerido.
                        </div>
                        <div *ngIf="formValues.phoneNumber.errors?.pattern">
                            Ingresa un número de télefono valido.
                        </div>

                    </div>
                </div>
                <div class="mb-3">
                    <div class="app-search dropdown  d-xl-block" ngbDropdown container="body"
                        placement="bottom-start">
                        <label for="templateToSearch" class="form-label">Seleccionar plantilla</label>
                        <div class="position-relative">
                            <input type="text" class="form-control" (keyup)="search()"
                                formControlName="templateToSearch" placeholder="Buscar..." id="topSearch"
                                ngbDropdownToggle
                                [ngClass]="{'is-invalid': messageBroadcast.submitted && broadcastFormControl.templateToSearch.invalid}">
                            <span class="mdi mdi-magnify search-icon"></span>
                            <div *ngIf="messageBroadcast.submitted && broadcastFormControl.templateToSearch.invalid"
                                class="invalid-feedback">
                                <div *ngIf="broadcastFormControl.templateToSearch.errors?.required">
                                    Selecciona una opción.
                                </div>
                            </div>
                        </div>

                        <div ngbDropdownMenu aria-labelledby="topSearch" class="dropdown-menu-animated dropdown-lg"
                            id="search-dropdown">
                            <ngx-simplebar style="max-height: 12rem;" #scrollframe class="m-0">
                                <div class="notification-list">
                                    <a ngbDropdownItem href="javascript:void(0);" *ngFor="let template of templatesList"
                                        class="notify-item" (click)="setCheckoutTemplate(template)">
                                        <div class="d-flex">
                                            <img class="d-flex me-2 rounded-circle" height="32">
                                            <div class="w-100">
                                                <h5 class="m-0 font-14">
                                                    {{template.name}}
                                                </h5>
                                                <span class="mb-0">
                                                    <p class="font-12 text-truncate">
                                                        {{template.body}}</p>
                                                </span>
                                            </div>
                                        </div>
                                    </a>
                                </div>
                            </ngx-simplebar>
                        </div>
                    </div>
                </div>
                <div class="mb-3">
                    <label class="custom-file-upload" [class.hidden]="!showFileHeaderInput"
                        *ngIf="selectedOption === 'image' || selectedOption === 'video' || selectedOption === 'document'">
                        <span>Elegir archivo {{ getSelectedFileTypeLabel() }}</span>
                        <input *ngIf="selectedOption === 'image'" type="file" class="form-control" accept=".jpg, .png"
                            (change)="handleFileUpload($event)" />
                        <input *ngIf="selectedOption === 'video'" type="file" class="form-control" accept=".mp4"
                            (change)="handleFileUpload($event)" />
                        <input *ngIf="selectedOption === 'document'" type="file" class="form-control" accept=".pdf"
                            (change)="handleFileUpload($event)" />
                    </label>
                </div>
                <!-- <div class="mb-3">
                    <small>Enviar conversaciones al tablero:</small>
                    <div class="input-group m-1">
                        <div class="ms-1">
                            <label for="selectBoard" class="form-label">Tablero</label>
                            <select class="form-select form-select-md" aria-label=".form-select-md" id="selectBoard"
                                name="selectBoard" (change)="fetchDataBoardStatuses()" formControlName="selectBoard">
                                <option hidden value="" disabled selected>Selecciona una opción</option>
                                <option *ngFor="let board of boards" [value]="board.id">
                                    {{board.name}}</option>
                            </select>
                        </div>

                        <div class="ms-1" [hidden]="this.boardStatuses.length == 0">
                            <label for="selectStatus" class="form-label">Etapa</label>
                            <select class="form-select form-select-md" aria-label=".form-select-md" id="selectStatus"
                                name="selectStatus" formControlName="selectStatus">
                                <option hidden value="" disabled selected>Selecciona una opción</option>
                                <option *ngFor="let status of boardStatuses" [value]="status.id">{{status.name}}
                                </option>
                            </select>
                        </div>
                    </div>
                </div> -->

                <div class="mb-3" *ngFor="let control of  inputFieldAsFormArray.controls ;let i = index;"
                    formArrayName='inputs'>
                    <div [formGroupName]="i" class="from-group">
                        <label for="contactName">Texto #{{i+1}}</label>
                        <input matInput type="text" class="form-control" placeholder="Texto" formControlName="input"
                            [ngClass]="{'is-invalid': messageBroadcast.submitted && control.controls.input.invalid}"
                            required>
                        <div *ngIf="messageBroadcast.submitted && control.controls.input.invalid"
                            class="invalid-feedback">
                            <div *ngIf="control.controls.input.errors?.required">
                                Campo requerido.
                            </div>
                        </div>
                    </div>
                </div>
                <div class="mb-2 border-top pt-2 text-end">
                    <button type="submit" class="btn btn-primary">Enviar</button>
                </div>
            </form>
        </div>

    </div>
</ng-template>

<ng-template #commitment let-modal>
    <div class="modal-body">
        <h4 class="modal-title font-22 text-light text-center" id="commitment">Acuerdo de compromiso </h4>

        <div class="m-3 p-3">
            <p class="font-24 text-light commitment">
                <b> " Me comprometo a responder y atender de manera cálida, profesional y efectiva los próximos
                    mensajes, es
                    mi responsabilidad la correcta guía y extracción de Feedback de mis clientes. "</b>
            </p>
        </div>
        <form class="input-group mb-1 ms-auto me-auto" style="width: 30rem;" (submit)="onAcceptCommitment()">
            <input type="text" class="form-control bg-transparent text-center text-white font-22 input-border"
                (input)="commitmentInput = commitmentInput.toUpperCase()" name="commitmentInput"
                [(ngModel)]="commitmentInput" placeholder='Escribe "ACEPTO" para continuar'>
            <div class="input-group-append">
                <button class="bg-transparent" role="submit" style="border: 0;">
                    <i class="mdi mdi-check h1 text-white"></i>
                </button>
            </div>
        </form>
        <div class="text-center mb-2" *ngIf="showAlert">
            <small class="text-warning font-16">Debes escribir la palabra "ACEPTO" correctamente.</small>
        </div>
        <div class="text-center mb-2">
            <small>Para continuar escribe la palabra "ACEPTO" en mayusculas.</small>
        </div>

    </div>
</ng-template>